import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { getErrorMessage } from 'utils';
import Response from '../../utils/response';
import { getEmptyData } from '../../utils/empty-types';
import { loaderStart, loaderEnd, setAlertMessage } from '../loader/loaderSlice';

export const getGenreAsync = createAsyncThunk('getGenreAsync', async ({ dispatch, payload }) => {
    // console.log('getGenreAsync fired', payload);
    dispatch(loaderStart());

    return await Response('GET_GENRE', payload)
        .then((data) => {
            dispatch(loaderEnd());

            if (data.success && data?.resultObj.data) return data?.resultObj.data;

            return [];
        })
        .catch((error) => {
            dispatch(loaderEnd());

            dispatch(setAlertMessage(getErrorMessage(error.message)));

            return [];
        });
});

export const addGenreAsync = createAsyncThunk('addGenreAsync', async ({ dispatch, formData }) => {
    dispatch(loaderStart());
    return await Response('ADD_GENRE', formData)
        .then((data) => {
            console.log('data', data);
            dispatch(loaderEnd());
            if (data.success) {
                const payload = { isDeleted: false };
                dispatch(getGenreAsync({ dispatch, payload }));

                dispatch(setAlertMessage('Genre saved successfully'));

                return [];
            }

            return [];
        })
        .catch((error) => {
            dispatch(loaderEnd());
            dispatch(setAlertMessage(getErrorMessage(error.message)));

            return [];
        });
});

export const removeGenreAsync = createAsyncThunk('removeGenreAsync', async ({ genreId, dispatch }) => {
    dispatch(loaderStart());

    return await Response('DELETE_GENRE', { genreId })
        .then((data) => {
            dispatch(loaderEnd());

            if (data.success) {
                const payload = { isDeleted: false };
                dispatch(getGenreAsync({ dispatch, payload }));
                dispatch(setAlertMessage('Genre removed successfully'));
                return [];
            }

            return [];
        })
        .catch((error) => {
            dispatch(loaderEnd());
            dispatch(setAlertMessage(getErrorMessage(error.message)));
            return [];
        });
});

export const editGenreAsync = createAsyncThunk('editGenreAsync', async ({ genreId, dispatch }) => {
    dispatch(loaderStart());

    return await Response('GET_GENRE', { _id: genreId })
        .then((data) => {
            dispatch(loaderEnd());

            if (data.success && data?.resultObj) return data?.resultObj.data[0];

            return {};
        })
        .catch((error) => {
            dispatch(loaderEnd());
            dispatch(setAlertMessage(getErrorMessage(error.message)));
            return {};
        });
});

export const updateGenreAsync = createAsyncThunk('updateGenreAsync', async ({ dispatch, formData }) => {
    dispatch(loaderStart());

    return await Response('UPDATE_GENRE', formData)
        .then((data) => {
            dispatch(loaderEnd());

            if (data.success) {
                const payload = { isDeleted: false };
                dispatch(getGenreAsync({ dispatch, payload }));

                dispatch(setAlertMessage('Genre updated successfully'));

                return [];
            }

            return {};
        })
        .catch((error) => {
            dispatch(loaderEnd());

            dispatch(setAlertMessage(getErrorMessage(error.message)));

            return {};
        });
});

export const genreSlice = createSlice({
    name: 'genre',
    initialState: {
        genreData: [],
        editGenreData: {}
    },
    reducers: {
        clearGenreState: (state, { payload }) => {
            state[payload.key] = getEmptyData(payload.type);
        }
    },
    extraReducers: {
        // Get
        [getGenreAsync.fulfilled]: (state, { payload }) => {
            state.genreData = payload;
        },

        //Edit
        [editGenreAsync.fulfilled]: (state, { payload }) => {
            state.editGenreData = payload;
        }
    }
});

export const { clearGenreState } = genreSlice.actions;

export const genreState = (state) => state.genre;

export default genreSlice.reducer;
