import axios from 'axios';
import config from '../config';
import apiEndpoints from 'config/api-endpoints';

const { base_url } = config;

const cb = (acc, cur) => {
    if (acc)
        acc += `&${cur[0]}=${cur[1]}`
    else
        acc += `${cur[0]}=${cur[1]}`

    return acc;
}

export default Response = async (key, data) => {
    let params = apiEndpoints[key];
    let url = base_url + params;
    let method = 'GET';

    const paramsArray = params.split(' ');

    if (paramsArray.length >= 2) {
        method = paramsArray[0];
        url = base_url + paramsArray[1];
    }

    // Dynamically Query Parameter

    if (params.includes('#')) {
        url = url.replace(/#/g, '/');

        const arr = Object.entries(data);

        if (arr.length > 0) url += arr.reduce((acc, cur) => `${acc}${acc ? '/' : ''}${cur[1]}`, '');
    }

    // Dynamically Query String

    if (params.includes('?')) {
        const arr = Object.entries(data);

        if (arr.length > 0) url += arr.reduce(cb, '');
    }

    return await Request({
        url,
        data,
        method
    });
};

const Request = async (options) => {
    axios.interceptors.request.use((req) => {
        if (localStorage.getItem('accessToken')) {
            req.headers.Authorization = `Bearer ${localStorage.getItem('accessToken')}`;
        }

        return req;
    });

    return await axios(options)
        .then((response) => {
            const { statusText, status, data } = response;

            let result = {};
            if (typeof data === 'object') {
                result = data;
                if (Array.isArray(data)) {
                    result.list = data;
                }
            } else {
                result.data = data;
            }

            return Promise.resolve({
                success: true,
                message: statusText,
                statusCode: status,
                ...result
            });
        })
        .catch((error) => {
            const { response, message } = error;

            let msg;
            let statusCode;

            if (response && response instanceof Object) {
                const { data, statusText } = response;
                statusCode = response.status;
                msg = data.message || statusText;
            } else {
                statusCode = 600;
                msg = error.message || 'Network Error';
            }

            return Promise.reject({
                success: false,
                statusCode,
                message: msg
            });
        });
};
