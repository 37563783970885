import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { getErrorMessage } from 'utils';
import Response from '../../utils/response';
import { getEmptyData } from '../../utils/empty-types';
import { loaderStart, loaderEnd, setAlertMessage } from '../loader/loaderSlice';

export const getHashtagAsync = createAsyncThunk('getHashtagAsync', async (dispatch) => {
    dispatch(loaderStart());

    return await Response('GET_HASHTAG')
        .then((data) => {
            dispatch(loaderEnd());

            if (data.success && data?.resultObj.data) return data?.resultObj.data;

            return [];
        })
        .catch((error) => {
            dispatch(loaderEnd());

            dispatch(setAlertMessage(getErrorMessage(error.message)));

            return [];
        });
});

export const addHashtagAsync = createAsyncThunk('addHashtagAsync', async ({ dispatch, postData }) => {
    dispatch(loaderStart());
    return await Response('ADD_HASHTAG', postData)
        .then((data) => {
            dispatch(loaderEnd());

            console.log('fffff', data);
            if (data.success) {
                dispatch(getHashtagAsync(dispatch));

                dispatch(setAlertMessage(data.displayMessage));

                return [];
            }

            dispatch(setAlertMessage(data.displayMessage));
            return [];
        })
        .catch((error) => {
            dispatch(loaderEnd());
            console.log('error ggg', error);
            dispatch(setAlertMessage(getErrorMessage(error.message)));

            return [];
        });
});

export const removeHashtagAsync = createAsyncThunk('removeHashtagAsync', async ({ id, dispatch }) => {
    dispatch(loaderStart());

    return await Response('DELETE_HASHTAG', { id })
        .then((data) => {
            dispatch(loaderEnd());

            if (data.success) {
                dispatch(getHashtagAsync(dispatch));
                dispatch(setAlertMessage('Hashtag removed successfully'));
                return [];
            }

            return [];
        })
        .catch((error) => {
            dispatch(loaderEnd());
            dispatch(setAlertMessage(getErrorMessage(error.message)));
            return [];
        });
});

export const editHashtagAsync = createAsyncThunk('editHashtagAsync', async ({ id, dispatch }) => {
    dispatch(loaderStart());

    return await Response('GET_HASHTAG_BY_ID', { id })
        .then((data) => {
            dispatch(loaderEnd());
            if (data.success && data?.resultObj) return data?.resultObj;

            return {};
        })
        .catch((error) => {
            dispatch(loaderEnd());
            dispatch(setAlertMessage(getErrorMessage(error.message)));
            return {};
        });
});

export const updateHashtagAsync = createAsyncThunk('updateHashtagAsync', async ({ dispatch, postData }) => {
    dispatch(loaderStart());

    return await Response('UPDATE_HASHTAG', postData)
        .then((data) => {
            dispatch(loaderEnd());

            if (data.success) {
                dispatch(getHashtagAsync(dispatch));

                dispatch(setAlertMessage('Hashtag updated successfully'));

                return;
            }

            return {};
        })
        .catch((error) => {
            dispatch(loaderEnd());

            dispatch(setAlertMessage(getErrorMessage(error.message)));

            return {};
        });
});

export const hashtagSlice = createSlice({
    name: 'hashtag',
    initialState: {
        hashtagData: [],
        editHashtagData: {}
    },
    reducers: {
        clearHashtagState: (state, { payload }) => {
            state[payload.key] = getEmptyData(payload.type);
        }
    },
    extraReducers: {
        // Get
        [getHashtagAsync.fulfilled]: (state, { payload }) => {
            state.hashtagData = payload;
        },

        //Edit
        [editHashtagAsync.fulfilled]: (state, { payload }) => {
            state.editHashtagData = payload;
        }
    }
});

export const { clearHashtagState } = hashtagSlice.actions;

export const hashtagState = (state) => state.hashtag;

export default hashtagSlice.reducer;
