import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import { authRoles } from '../config/roles';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));
// category master routing
const CategoryMaster = Loadable(lazy(() => import('views/pages/master/category/CategoryMaster')));
// genres master routing
const GenresMaster = Loadable(lazy(() => import('views/pages/master/genres/GenresMaster')));
// hashtag master routing
const HashtagMaster = Loadable(lazy(() => import('views/pages/master/hashtag/HashtagMaster')));
// video master routing
const VideoMaster = Loadable(lazy(() => import('views/pages/master/video/VideoMaster')));
// subscription master routing
const SubscriptionMaster = Loadable(lazy(()=> import('views/pages/master/subscription/SubscriptionMaster')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/dashboard',
    element: <MainLayout />,
    children: [
        {
            path: '/default',
            element: <DashboardDefault />,
            role: authRoles.admin
        },
        {
            path: '/category-master',
            element: <CategoryMaster />,
            role: authRoles.admin
        },
        {
            path: '/genres-master',
            element: <GenresMaster />,
            role: authRoles.admin
        },
        {
            path: '/hashtag-master',
            element: <HashtagMaster />,
            role: authRoles.admin
        },
        {
            path: '/video-master',
            element: <VideoMaster />,
        },
        {
            path: '/subscription-master',
            element: <SubscriptionMaster />,
            role: authRoles.admin
        }
    ]
};

export default MainRoutes;
