// assets
import { IconKey } from '@tabler/icons';
import { authRoles } from 'config/roles';

// constant
const icons = { IconKey };

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const pages = {
    id: 'masters',
    title: 'Masters',
    caption: 'All master pages here',
    type: 'group',
    children: [
        {
            id: 'master',
            title: 'Master',
            type: 'collapse',
            icon: icons.IconKey,
            children: [
                {
                    id: 'category',
                    title: 'Category Master',
                    type: 'item',
                    url: '/dashboard/category-master',
                    target: false,
                    role: authRoles.admin
                },
                {
                    id: 'genres',
                    title: 'Genre Master',
                    type: 'item',
                    url: '/dashboard/genres-master',
                    target: false,
                    role: authRoles.admin
                },
                {
                    id: 'hashtag',
                    title: '#Tag Master',
                    type: 'item',
                    url: '/dashboard/hashtag-master',
                    target: false,
                    role: authRoles.admin
                },
                {
                    id: 'video',
                    title: 'Video Master',
                    type: 'item',
                    url: '/dashboard/video-master',
                    target: false,
                    role: authRoles.admin
                },
                {
                    id: 'subscription',
                    title: 'Subscription',
                    type: 'item',
                    url: '/dashboard/subscription-master',
                    target: false,
                    role: authRoles.admin
                }
            ]
        }
    ]
};

export default pages;
