import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Response from '../../utils/response';
import { loaderStart, loaderEnd, setAlertMessage } from 'features/loader/loaderSlice';

export const subscriptionAsync = createAsyncThunk('subscription/subscriptionAsync', async (dispatch) => {
    dispatch(loaderStart());
    return await Response('GET_SUBSCRIPTION', dispatch)
        .then((data) => {
            dispatch(loaderEnd());
            if (data.success && data?.resultObj) {
                return data?.resultObj;
            }
            return {};
        })
        .catch((error) => {
            dispatch(loaderEnd());
            return {
                error
            };
        });
});

export const subscriptionSlice = createSlice({
    name: 'subscription',
    initialState: {
        subscriptionData: []
        // isLoading: false,
        // message: ''
    },
    extraReducers: {
        [subscriptionAsync.fulfilled]: (state, { payload }) => {
            state.subscriptionData = payload;
        }
    }
});

export const subscriptionState = (state) => state.subscription;

export default subscriptionSlice.reducer;
