import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Response from '../../utils/response';
import { getEmptyData } from 'utils/empty-types';
import { loaderStart, loaderEnd, setAlertMessage } from 'features/loader/loaderSlice';

export const dashboardAsync = createAsyncThunk('dashboard/dashboardAsync', async (dispatch) => {
    dispatch(loaderStart());

    return await Response('GET_TOTAL_COUNT', dispatch)
        .then((data) => {
            dispatch(loaderEnd());
            if (data.success && data?.resultObj) {
                console.log('Sucess Data Catch');
                return data?.resultObj[0];
            }

            return {};
        })
        .catch((error) => {
            dispatch(loaderEnd());
            return {};
        });
});

export const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState: {
        dashboardData: {}
    },
    extraReducers: {
        [dashboardAsync.fulfilled]: (state, { payload }) => {
            state.dashboardData = payload;
        }
    }
});

export const dashboardState = (state) => state.dashboard;

export default dashboardSlice.reducer;
