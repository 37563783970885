export default {
    // --- USER END-POINTS --- //

    LOGIN_USER: 'POST /ADMIN/LOGIN',

    // --- CATEGORY END-POINTS --- //

    GET_CATEGORY: '/CATEGORY',
    ADD_CATEGORY: 'POST /CATEGORY',
    UPDATE_CATEGORY: 'PATCH /CATEGORY',
    GET_CATEGORY_BY_ID: '/CATEGORY#',
    DELETE_CATEGORY: 'DELETE /CATEGORY#',

    // --- GENRE END-POINTS --- //

    GET_GENRE: 'POST /GENRE/GET',
    ADD_GENRE: 'POST /GENRE/',
    UPDATE_GENRE: 'PATCH /GENRE/',
    DELETE_GENRE: 'DELETE /GENRE#',

    // ---HASHTAG END-POINTS ---- //
    GET_HASHTAG: '/HASHTAG',
    ADD_HASHTAG: 'POST /HASHTAG',
    UPDATE_HASHTAG: 'PATCH /HASHTAG',
    GET_HASHTAG_BY_ID: '/HASHTAG#',
    DELETE_HASHTAG: 'DELETE /HASHTAG#',

    // ---DASHBOARD END-POINTS ---- //
    GET_TOTAL_COUNT: '/ANALYSIS/',

    // ---SUBSCRIPTION END-POINTS--- //
    GET_SUBSCRIPTION: '/SUBSCRIPTION'
};
